.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.horizontal-align {
  display: inline-flex;
}

.margin-bothside {
  margin-left: 300px;
  margin-right: 300px;
}

.btn {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 2px !important;
  padding-right: 2px !important;
  font-size: 14px !important;

}

.btn-font{
  font-size: 11px !important;
}

.profile-banner h1 {
  font-size: 1.7rem;
  font-weight: 700;
  color: #ed156e;
  font-family: Verdana;
  margin-bottom: 20px
}

.cursor-pointer {
  cursor: pointer;
}

.btn-primary-startNow{background-image:linear-gradient(#fff,#fff),linear-gradient(90deg,#00b7f1,#ed156e)!important;background-origin:border-box;background-clip:padding-box,border-box;border-image-source:linear-gradient(90deg,#00B7F1,#ED156E)!important;color:#1a3a4c !important;border:2px solid;padding:6px 40px;background-color:#1a3a4c00;font-weight:800;font-size:0.875rem;line-height:17px;font-weight:700!important;font-family:Verdana}
.btn-primary-startNow:hover{   background-clip: padding-box,border-box;background-image: linear-gradient(90deg,#00b7f1,#ed156e),linear-gradient(90deg,#00b7f1,#ed156e)!important;background-origin: border-box;border: 2px solid;border-image-source: linear-gradient(90deg,#00b7f1,#ed156e)!important; color: #fff !important;}


