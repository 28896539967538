body, html {
    height: 100%;
    margin: 0;    
    font-family: 'museo500';
  }
.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    content: "\f0c9";
    font-family: 'fontawesome';
} 
body.login-ary{
    background: url(/public/images/body_img.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: #fff;
    max-height: 100%;
}
.login-container{ width: calc(100% - 10%); margin: 0 auto;}
.login{padding: 20px 0;}
.login h1 {font-size: 5rem; font-weight: 100; line-height: 45px; font-family: 'museo100';}
.login p {font-size: 1.9rem; font-weight: 100; font-family: 'museo100';}
.login-sec .col-md-4 {width: 350px;}
.login .ms-5 {margin-left: 10% !important;}
.login .pt-5{margin-top: 30px !important;}
.login h3{margin-top: 20px; font-family: 'museo700'; font-size: 1.9rem;}
/* .ttxt h3{
    font-family: 'museo100';
   
} */
.login .form-check{padding-left:45px;}
.login .form-check label{font-size: 16px; font-family:'museo500' }
.login .form-row{margin-top:20px;}
.text-right{text-align: right;}
.text-right a {font-size: 16px; font-family:'museo500' ;}
.login a{text-decoration: none; color: #FF6464; display: block;}
.login a:hover{color:#fff; text-decoration: none;}
.login button{border-radius: 33px;
    padding: 5px 20px;
    width: 200px;
    background: #63C3DD;
    border: none;
    color: #1A3A4C;
    height: 40px;
}
/* .login .form-control{border-radius: 50px; color: #1d2124; padding-left: 20px; height: 35px; font-style: italic; border:none; background-color:rgba(255,255,255,0.6);} */
.login .form-control {
    border-radius: 33px;
    color: #1d2124;
    padding-left: 20px;
    height: 40px;
    font-style: italic;
    border: none;
    background-color: rgba(255,255,255,0.6);
    width: 100%;
}
.fixed-footer{color:#FFFFFF; opacity: .5; position: absolute; bottom: 20px;}


/* End Login screen */
.dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.55em;
    vertical-align: 0em;
    content: "\f107";
    font-family: 'fontawesome';
    border-top: 0;
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
}
/* .dropdown-toggle.show::after {
    display: inline-block;
    margin-left: 0.55em;
    vertical-align: 0em;
    content: "\f107";
    font-family: 'fontawesome';
    border-top: 0;
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
} */
.bg-menu-light.navbar { padding-top: 0.1rem; padding-bottom: 0rem;}
.bg-menu-light { background: #63C3DD; color: #191919; border-top: 1px solid #fff;  border-bottom: 1px solid #fff; z-index: 2;}
.bg-menu-dark { background: #1A3A4C; color: #191919;}
.nav-link { color: #191919; padding: 0.5rem 1.8rem !important;}
.livebutton { background: #1A3A4C; border-radius: 5px; color: #fff; padding: 0rem 1rem;}
.livebutton .img-left {height: 20px; padding-right: 0.5rem;}
.livebutton .img-right {height: 20px; padding-left: 0.5rem;}
.livestrembtn .dropdown-toggle::after {display: none;}
.livestrembtn .nav-link.show .livebutton { background: #ff6464;}
.projectlogo {color: #63c3dd; font-family: 'museo700'; line-height: 0;}
.projectlogo sup{ 
    font-size: 10px;
    vertical-align: super;
    background: #63c3dd;
    border-radius: 50%;
    padding: 2px 5px;
    color: #1a3a4c;
}
.projectlogo span{font-family: 'museo100';}
.niitlogo {height: 34px; width: 100%; padding: 0rem 2.5rem;}
.searchbox input { height: 30px; margin-top: 4px; border-radius: 30px 0px 0px 30px; border-right: 0;}
.searchbox button {
    font-size: 16px;
    color: #fff;
    border-radius: 0px 30px 30px 0px;
    border: 1px solid #fff;
    height: 30px;
    margin-top: 4px;
    border-left: 0;
    padding: 2px 25px;
}
button.searchbtn:hover {color: #fff; background: #ff6464;
}
/* .searchbox button::after { height: 30px; margin-top: 4px; border-radius: 30px 0px 0px 30px; content: "\f002"; font-family: 'fontawesome';} */
.imgleftsec {
    background: url(/public/images/home-banner.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 350px;
    position: relative;
}

img.user-icon_img {
    position: relative;
    bottom: 53px;
    right: 26px;
}
.banner-text {color: #1a3a4c; position: absolute; bottom: 50px; left: 45px;     z-index: 2;}
.banner-text p {margin-bottom: 0rem;}
.banner-text .webinarinfo {color: #FF6464; font-family: 'museo700';}
.banner-text .webinarinfo span{color: #FF6464; font-family: 'museo100';}
.banner-btn {
    background: #1a3a4c;
    color: #fff;
    font-size: 13px;
    font-family: 'museo500';
    border: 0;
    border-radius: 5px;
    padding: 0.2rem 1rem;
    margin-left: 20px;
}
#carouselExampleIndicators .carousel-inner .carousel-item img {height: 345px;}
/* #carouselExampleIndicators .carousel-inner .carousel-item img {height: 220px;} */
#carouselExampleIndicators .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 3%;
    margin-bottom: 1rem;
    margin-left: 3%;
    list-style: none;
    background: #1A3A4CCC;
    border-radius: 10px;
}
#carouselExampleIndicators .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 100%;
    /* width: 20%; */
    height: 3px;
    padding: 0;
    margin-right: 0px;
    margin-left: 0px;
    text-indent: -999px;
    cursor: pointer;
    background-color: transparent;
    background-clip: padding-box;
    border: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    transition: opacity .6s ease;
    border-radius: 6px;
}
#carouselExampleIndicators .carousel-indicators .active {
    opacity: 1;
    border: 0;
    background: #fff;
    margin-top: 4px;
    height: 5px;
    margin-left: 5px;
    margin-right: 5px;
}

.backrightimg {
    background: url(/public/images/right-back.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    padding: 0;
}
.infosection {position: absolute; bottom: 0; margin: 0;}
.user-img { width: 100px; height: 100px; border: 2px solid #fff; border-radius: 50%; margin-top: -50px;}
.userinfo-bg {background-color: #FF6464; text-align: center; color: #fff;}
.user-details-view {background: #fff;  opacity: .9;}
.upgrate-bnrbtn {margin: 20px 0px 20px;}
.userinfo-bg h4 {font-size: 20px; margin-top: 20px; font-family: 'museo700';}
.userinfo-bg span {font-size: 13px;}
.finishit-btn {margin: 0px 0px 0px 20%;}
.main-body-conent {margin: 20px 0px;}

/* Accordian */
.accordion-button:not(.collapsed) {
    color: #fff;
    background-color: #1A3A4C;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}
.accordion-item:first-of-type .accordion-button {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-family: 'museo700';
    margin-top: 0px;
}
.accordion-item .accordion-button {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-family: 'museo700';
    margin-top: 30px;
}
.accordion-body {
    padding: 1rem 1.25rem;
    background: #F1F1F1;
    border-radius: 0px 0px 10px 10px;
    min-height: 100vh;
}
.homeaccordian .accordion-body {
    padding: 0;
    background: #F1F1F1;
    border-radius: 0px;
    min-height: auto;
}

.cust_i{

    margin-bottom: 10px;
}
.accordion-item {
    background-color: #fff;
    border: 0;
}
.accordion-button::after {
    flex-shrink: 0;
    width: 1.5rem;
    height: 1.5rem;
    background: #fff;
    border-radius: 50%;
    padding: 15px;
}
.weekselector {margin-left: 30px; font-family: 'museo100';}
.weekselector span::after {
    content: "\f105";
    font-family: 'fontawesome';
    background: #fff;
    color: #1a3a4c;
    font-size: 25px;
    font-weight: 700;
    padding: 0px 8px;
    border-radius: 50%;
    margin-left: 10px;
}
.weekselector span::before {
    content: "\f104";
    font-family: 'fontawesome';
    background: #fff;
    color: #1a3a4c;
    font-size: 25px;
    font-weight: 700;
    padding: 0px 8px;
    border-radius: 50%;
    margin-right: 10px;
}
.accordion-button { color: #fff;  background-color: #1a3a4c;}
.weektable-acc {padding: 0; overflow: auto;}
.stepsection {overflow: auto;}
.weektable-acc .table-bordered thead tr {
    text-align: center;
    background: #929292;
    color: #fff;
    font-family: 'museo100';
    font-weight: 100 !important;
    letter-spacing: 2px;
    font-size: 14px;
    border-top: 0;
}
.weektable-acc .table-bordered thead tr th{width: 20%;}
.weektable-acc .table>:not(:first-child) {border-top: 0px solid transparent !important;}
.active-week {background: #00B70E !important; font-family: 'museo700';}
.inactive-week-content {background: #B1B1B1 !important;}
.active-week-content {background: #fff !important;}
.weektable-acc tbody tr td span{font-family: 'museo700';}
.weektable-acc tbody tr td {font-size: 13px; line-height: 1.3;}
/* End Accordian */
.viewlikecomment {font-size: 14px; margin: 10px 0 20px 0;}
.viewlikecomment span {color: #1a3a4c;}
.mynetword-cards .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: transparent;
    background-clip: border-box;
    border: 0;
    border-radius: 0;
    padding: 20px 0px;
}
.mynetword-cards .card hr {
    border: 5px solid #B1B1B1;
    margin-top: 40px;
    margin-bottom: 00px;
}
.mynetword-cards {overflow: auto; height: 637px; padding: 0px 10px;}
/* Custom scroll bar */
.mynetword-cards::-webkit-scrollbar {
    width: 3px;
}
.mynetword-cards::-webkit-scrollbar-thumb {
    border-radius: 0rem;
    background-color: #63C3DD;
    background-color: -webkit-color;
    width: 6px;
}
.mynetword-cards::-webkit-scrollbar-track {
    background: #1a3a4c;
}
/* End Custom scroll bar */
.home-footer {
    text-align: center;
    padding: 15px;
    background: #1a3a4c;
    color: #bebcbc;
    border-top: 2px solid #848586;
}


/* --------------scrollbar-home------------ */

.needh_scroll::-webkit-scrollbar-track {
    background: #1a3a4c;
}

.needh_scroll::-webkit-scrollbar-thumb {
    border-radius: 0rem;
    background-color: #63C3DD;
    background-color: -webkit-color;
    width: 6px;
}

.needh_scroll
             {overflow: auto; height: 637px; padding: 0px 10px;}

             .needh_scroll::-webkit-scrollbar {
                width: 3px;
            }

            .needh_scroll .card hr {
                border: 5px solid #B1B1B1;
                margin-top: 40px;
                margin-bottom: 00px;
            }


            

/* --------------scrollbar-home------------ */


/* -----start-------toggle--btn---------------- */
button.needhelpbtn {
    background: none repeat scroll 0 0 #0774E1;
    border: none;
    color: #FFFFFF;
    height: 45px;
    right: -52px;
    position: fixed;
    top: 330px;
   
    z-index: 2;
    border-radius: 10px 10px 0px 0px;
    
    letter-spacing: 1px; 
}


/* button.needhelpbtn { background: none repeat scroll 0 0 #0774E1; border: medium none;
 color: #FFFFFF; height: 60px; right: -50px; position: fixed; top: 290px; width: 160px;
 z-index: 2; border-radius: 10px 10px 0px 0px; transform: rotate(-90deg); letter-spacing: 2px;} */
 

 button.needhelpbtn {
    background: none repeat scroll 0 0 #0774E1;
    border: medium none;
    color: #FFFFFF;
    height: 33px;
    right: 0px;
    position: fixed;
    /* top: 0px; */
   
    z-index: 2;
    border-radius: 10px 10px 0px 0px;
   
    letter-spacing: 2px;
    
    padding: 3px;

}
 
.slide-toggle.bothicon .needhtext {display: none;}
.slide-toggle .canceltext {display: none;}
.slide-toggle .needhtext {display: block;}
.slide-toggle.bothicon .canceltext {display: block;}

.slide-toggle.bothicon .needhtext {display: none;}
.slide-toggle .needhtext {display: block;}

.slide-toggle .canceltext {display: none;}
.slide-toggle.bothicon .canceltext {display: block;}

.needhcontainer{
    float: right;
    overflow: hidden;
    background: #fff;
    position: fixed;
    right: 40px;
    top: 150px;
    z-index: 1;
    border-radius: 10px;
    width: 92%;
    box-shadow: 0px 0px 15px #949191;
    padding: 35px;
    height: auto;
}



.needhelptog ul {height: 105px; overflow-y: auto; text-align: left;}
.needhelptog ul li {
    font-size: 12px;
    padding: inherit;    
    list-style-type: circle;
    margin: inherit;
    margin-left: 16px;
}
.needhelptog h4 {font-weight: 700; text-align: center;}
.needhelptog div {border-right: 1px solid #dedede; text-align: center;}
.needhelptog div:last-child {border-right: 0px solid #dedede;}
.needhelptog button {text-align: center;}



.btnrequest {
    border-radius: 20px;
    padding: 7px 35px;
    background: #0774e1;
    color: #fff;
    margin-top: 10px;
}


/* -------end-----toggle--btn---------------- */


.mynetword-cards .card-title { margin-bottom: 0rem; font-family: 'museo700'; color: #1a3a4c;;}
.card-details-top .user-card-img{vertical-align: bottom;}

/* ------- chart --------- */
.chart-two {
  width: 50%;
  height: 200px;
  /* margin: 0; */
  position: relative;
  margin-bottom: -34px;
}
.chart-two.animate svg .circle-foreground {
  -webkit-animation: offset 3s ease-in-out forwards;
          animation: offset 3s ease-in-out forwards;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}
.chart-two.animate figcaption:after {
  -webkit-animation: chart-two-label 3s steps(50) forwards;
          animation: chart-two-label 3s steps(50) forwards;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}
.chart-two svg {
  width: 100%;
  height: 100%;
  position: absolute;
}
.chart-two svg .circle-background, .chart-two svg .circle-foreground {
  r: 50.5px;
  cx: 50%;
  cy: 50%;
  fill: none;
  stroke: #305556;
  stroke-width: 5px;
}
.chart-two svg .circle-foreground {
  stroke: #d0f09e;
  stroke-dasharray: 290.45px 580.9px;
  stroke-dashoffset: 290.45px;
  stroke-linecap: round;
  transform-origin: 50% 50%;
  transform: rotate(-90deg);
}
.chart-two figcaption {
  display: inline-block;
  width: 100%;
  height: 2.5rem;
  overflow: hidden;
  text-align: center;
  color: #1a3a4c;
  position: absolute;
  top: calc(589% - 5.25rem);
  left: 0;
  font-size: 0;
  top:82px
}
.chart-two figcaption:after {
  display: inline-block;
  content: "0%\a 1%\a 2%\a 3%\a 4%\a 5%\a 6%\a 7%\a 8%\a 9%\a 10%\a 11%\a 12%\a 13%\a 14%\a 15%\a 16%\a 17%\a 18%\a 19%\a 20%\a 21%\a 22%\a 23%\a 24%\a 25%\a 26%\a 27%\a 28%\a 29%\a 30%\a 31%\a 32%\a 33%\a 34%\a 35%\a 36%\a 37%\a 38%\a 39%\a 40%\a 41%\a 42%\a 43%\a 44%\a 45%\a 46%\a 47%\a 48%\a 49%\a 50%\a 51%\a 52%\a 53%\a 54%\a 55%\a 56%\a 57%\a 58%\a 59%\a 60%\a 61%\a 62%\a 63%\a 64%\a 65%\a 66%\a 67%\a 68%\a 69%\a 70%\a 71%\a 72%\a 73%\a 74%\a 75%\a 76%\a 77%\a 78%\a 79%\a 80%\a 81%\a 82%\a 83%\a 84%\a 85%\a 86%\a 87%\a 88%\a 89%\a 90%\a 91%\a 92%\a 93%\a 94%\a 95%\a 96%\a 97%\a 98%\a 99%\a 100%\a";
  white-space: pre;
  font-size: 2rem;
  line-height: 2.5rem;
}

@-webkit-keyframes chart-two-label {
  100% {
    transform: translateY(-125rem);
  }
}

@keyframes chart-two-label {
  100% {
    transform: translateY(-125rem);
  }
}
/* End Chart */
/* Step Progress Bar */
.steps {
    list-style: none;
    margin: 0;
    padding: 0;
    display: table;
    table-layout: fixed;
    width: 100%;
    color: #929292;
    height: 4rem;
    background: #b1b1b1;
  }
  .steps > .step {
    position: relative;
    display: table-cell;
    text-align: center;
    font-size: 0.875rem;
    color: #6D6875;
    height: 285px;
    padding: 6px;
    border: 1px solid #fff;
    border-top: 0;
    border-bottom: 0;
    line-height: 15px;
  }
  .steps > .step:before {
    content: attr(data-step);
    display: block;
    margin: 0 auto;
    background: #B1B1B1;
    border: 2px solid #ffffff;
    color: #e6e6e6;
    width: 2rem;
    height: 2rem;
    text-align: center;
    margin-bottom: -4.2rem;
    line-height: 1.9rem;
    border-radius: 100%;
    position: relative;
    z-index: 1;
    font-weight: 700;
    font-size: 0rem;
  }
  .steps > .step:after {
    content: "";
    position: absolute;
    display: block;
    background: #e6e6e6;
    width: 100%;
    height: 0.25rem;
    top: 28px;
    left: 0%;
  }
  .steps > .step:last-child:after {width: 50%;}
  .steps > .step:first-child:after {width: 50%; left: 50%;}
  .steps > .step.step-complete {
    color: #fff; 
    background: #FF6464;       
  }
 .steps > .step.step-complete:before {
    color: #191919;
    background: #00B70E;
    border: 4px solid #191919;
    font-size: 1.1rem;
    line-height: 7px;
    font-weight: 900;
    content: "\f00c";
    font-family: 'fontawesome';
    padding: 5px 3px;
  }
  .steps > .step.step-complete:after {
    background: #191919;
  }
  .steps > .step.step-active {
    font-size: 14px;
    background: #FF6C00;
    color: #fff;
  }
  .steps > .step.step-active:before {
     color: #1a3a4c;
    border: 2px solid #ffffff;
    background: #ff6c00;
    margin-bottom: -4.9rem;
    width: 2.5rem;
    height: 2.5rem;    
    line-height: 34px;
    font-size: 14px;
  }
  
/* End Step Progress Bar */

.progress-cont {
    -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  transform-origin: 0 0;
  position: absolute;
    bottom: 0;
    text-align: left;
}
.progress-cont a {text-decoration: none; color: inherit;}
.progress-cont p{ margin-bottom: 0;}
.progress-cont span{ white-space: nowrap;}
/* .carousel.slide {z-index: 1;height: 249px;} */
.carousel.slide {z-index: 1;height: 345px;}
.livestrembtn.dropdown .dropdown-menu li a::after {display: none;}
.navbar-nav .livestrembtn .dropdown-item {padding: 10px 20px 10px 20px;}
.live-footer-menu-btn {float: right; margin-top: 15px; margin-bottom: 10px;}
.more-detail-btn {background: #FFFFFF; border-radius: 5px;  border: 0; padding: 2px 12px;}
/* Dropdown menu */
.navbar-nav .dropdown-menu {
    background-color: #1a3a4c;
    margin-top: 0px;
    border-radius: 0;
    padding: 0;
    min-width: 12rem;
    z-index: 9;
}
.navbar-nav .dropdown-item {color: #fff; padding: 10px 85px 10px 20px; align-items: center; justify-content: left; display: flex;}
.nav-link.show {background: #1a3a4c; color: #fff;}
.dropdown .dropdown-menu li {border-bottom: 1px solid #fff;}
.dropdown .dropdown-menu li a:hover {background: #1a3a4c;}
.dropdown .dropdown-menu li a::after {
    content: "\f105";
    font-family: 'fontawesome';
    position: absolute;
    right: 15px;
    background: #fff;
    color: #1a3a4c;
    border-radius: 50%;
    padding: 0px 5px;
    height: 15px;
    width: 15px;
    line-height: .9;
}
.dropdown .dropdown-menu li:last-child {border-bottom: 0px solid #fff;}
.menu-head {display: block; font-family: 'museo700';}
.menu-content {display: block; font-family: 'museo100'; font-size: 12px;}
.navbar-nav .dropdown-menu.menu-cont {right: 0; left: auto;}

.navbar-nav .menu-cont .dropdown-item {
    color: #fff;
    padding: 10px 20px 10px 20px;
    align-items: center;
    justify-content: left;
    display: flex;
}
.dropdown .dropdown-menu.menu-cont li a::after { display: none; }
.menu-cont .dropdown-item i{margin-right: 15px; color: #63c3dd;}

.handshake {
    background-image: url(/public/images/onboarding-menu.png); background-repeat: no-repeat; display: inline-block;
    width: 27px; height: 22px; vertical-align: middle;
}
.left-menu li.active a i.handshake {
    background-image: url(/public/images/onboarding-active.png); background-repeat: no-repeat; display: inline-block;
    width: 27px; height: 22px; vertical-align: middle;
}
.dashboard {
    background-image: url(/public/images/dashboard-menu.png); background-repeat: no-repeat; display: inline-block;
    width: 27px; height: 17px; vertical-align: middle;
}
.left-menu li.active a i.dashboard {
    background-image: url(/public/images/dashboard-active.png); background-repeat: no-repeat; display: inline-block;
    width: 27px; height: 17px; vertical-align: middle;
}
.notifications {
    background-image: url(/public/images/notification-menu.png); background-repeat: no-repeat; display: inline-block;
    width: 25px; height: 24px; vertical-align: middle;
}
.left-menu li.active a i.notifications {
    background-image: url(/public/images/notification-active.png); background-repeat: no-repeat; display: inline-block;
    width: 25px; height: 24px; vertical-align: middle;
}
.mylearningcenters {
    background-image: url(/public/images/mylearning-menu.png); background-repeat: no-repeat; display: inline-block;
    width: 26px; height: 24px; vertical-align: middle;
}
.left-menu li.active a i.mylearningcenters {
    background-image: url(/public/images/mylearning-active.png); background-repeat: no-repeat; display: inline-block;
    width: 26px; height: 24px; vertical-align: middle;
}
.myevents {
    background-image: url(/public/images/myevent-menu.png); background-repeat: no-repeat; display: inline-block;
    width: 22px; height: 24px; vertical-align: middle;
}
.left-menu li.active a i.myevents {
    background-image: url(/public/images/myevent-active.png); background-repeat: no-repeat; display: inline-block;
    width: 22px; height: 24px; vertical-align: middle;
}
.favorites {
    background-image: url(/public/images/favorites-menu.png); background-repeat: no-repeat; display: inline-block;
    width: 23px; height: 20px; vertical-align: middle;
}
.left-menu li.active a i.favorites {
    background-image: url(/public/images/favorites-active.png); background-repeat: no-repeat; display: inline-block;
    width: 23px; height: 20px; vertical-align: middle;
}
.notes {
    background-image: url(/public/images/notes-menu.png); background-repeat: no-repeat; display: inline-block;
    width: 20px; height: 20px; vertical-align: middle;
}
.left-menu li.active a i.notes {
    background-image: url(/public/images/notes-active.png); background-repeat: no-repeat; display: inline-block;
    width: 20px; height: 20px; vertical-align: middle;
}
.mynetworks {
    background-image: url(/public/images/mynetworks-menu.png); background-repeat: no-repeat; display: inline-block;
    width: 24px; height: 24px; vertical-align: middle;
}
.left-menu li.active a i.mynetworks {
    background-image: url(/public/images/mynetworks-active.png); background-repeat: no-repeat; display: inline-block;
    width: 24px; height: 24px; vertical-align: middle;
}
.settings {
    background-image: url(/public/images/settings-menu.png); background-repeat: no-repeat; display: inline-block;
    width: 24px; height: 24px; vertical-align: middle;
}
.left-menu li.active a i.settings {
    background-image: url(/public/images/settings-active.png); background-repeat: no-repeat; display: inline-block;
    width: 24px; height: 24px; vertical-align: middle;
}
.signout {
    background-image: url(/public/images/signout-menu.png); background-repeat: no-repeat; display: inline-block;
    width: 25px; height: 24px; vertical-align: middle;
}
.premium-ic {
    background-image: url(/public/images/upgradetopr.png); background-repeat: no-repeat; display: inline-block;
    width: 27px; height: 24px; vertical-align: middle;
}
/* End Dropdown Menu */

/* body.modal-open {filter: grayscale(5);} */

.attention .modal-content {
    border-radius: 0 0px 20px 20px;
    background: #1A3A4C;
    border: 0;
    color: #fff;
    text-align: center;
}
.attention .modal-dialog {
    max-width: 670px;
    margin: 6.7rem auto;
}
.attention .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 2rem;
}
.attention-btn {
    background: #FF6464;
    border: 0;
    border-radius: 5px;
    padding: 2px 20px;
    color: #fff;
    font-size: 11px;
    width: 136px;
    border: none;
    /* margin-top: 23px; */
}


/* .attention-btn1 {
    background: #FF6464;
    border: 0;
    border-radius: 5px;
    padding: 2px 20px;
    color: #fff;
    font-size: 11px;
    width: 136px;
    border: none;
} */
.attention-head {
    color: #FF6C00;
    margin-bottom: 2rem;
}

/* Dashboard view */
.sidenav {
    /* position: fixed;
    top: 61px;
    left: 0; */
    min-height: 100vh;
    width: 280px;
    overflow: hidden;
    background: #1A3A4C;
    overflow-y: auto;
    z-index: 1;
    padding: 0;
}
.sidenav::-webkit-scrollbar { width: 3px;}
.sidenav::-webkit-scrollbar-thumb {border-radius: 0rem; background-color: #63C3DD; background-color: -webkit-color; width: 6px;}
.sidenav::-webkit-scrollbar-track { background: #1a3a4c;}
.main-content {
    width: calc(100% - 280px);
    float: right;
    padding: 25px 30px;
    background: #fff;
    min-height: 100vh;
}
/* End Dashboard view */
.accordion-button:hover {z-index: 1;}
.user-show-sec {margin-bottom: 15px;}
/* .user-dash {max-height: 138px;} */
.dash-user-info {padding: 10px 20px; text-align: center; background: #fff; color: #1A3A4C;}
.profile-user-info {padding: 10px 20px;height: 120px; text-align: center; background: #FF6464; color: #fff;}
.profile-user-info p { color: #fff; font-size: 14px;}
.profile-user-info h5 { font-weight: 600; font-size: 20px;}
.profile-dash{width: 100% !important;}
.dash-user-info p{font-size: 14px; line-height: 14px; margin-bottom: 0;}
.dash-user-info h5 {font-family: 'museo700'; margin-bottom: 0; font-weight: 700;}
ul.left-menu {list-style: none; padding-left: 1rem; padding-top: 30px;}
ul.left-menu li {padding: 10px 20px;}
ul.left-menu li a{text-decoration: none; color: #fff; display: flex;}
ul.left-menu li a span {width: 40px; display: inline-block;}

.main-content .accordion-button::after { display: none; }
.acc-btn-cont {padding: 0px 20px;}

.left-menu li.active {background: #fff; border-radius: 10px 0px 0px 10px;}
.left-menu li.active a{color: #1A3A4C;}

/* Circular progress bar */
.progress-circular {
    width: 60px;
    height: 60px !important;
    float: left;
    line-height: 150px;
    background: none;
    margin: 15px;
    box-shadow: none;
    position: relative
}

.progress-circular:after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 5px solid #fff;
    position: absolute;
    top: 0;
    left: 0
}

.select_headg {
    margin-bottom: 0;
}

.progress-circular>span {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1
}

.progress-circular .progress-left {
    left: 0
}

.progress-circular .progress-bar {
    width: 100%;
    height: 100%;
    background: none;
    border-width: 5px;
    border-style: solid;
    position: absolute;
    top: 0
}

.progress-circular .progress-left .progress-bar {
    left: 100%;
    border-top-right-radius: 80px;
    border-bottom-right-radius: 80px;
    border-left: 0;
    -webkit-transform-origin: center left;
    transform-origin: center left
}

.progress-circular .progress-right {
    right: 0
}

.progress-circular .progress-right .progress-bar {
    left: -100%;
    border-top-left-radius: 80px;
    border-bottom-left-radius: 80px;
    border-right: 0;
    -webkit-transform-origin: center right;
    transform-origin: center right;
    animation: loading-1 1.8s linear forwards
}

.progress-circular .progress-value {
    width: 90%;
    height: 90%;
    border-radius: 50%;
    background: #F1F1F1;
    font-size: 16px;
    color: #1a3a4c;
    line-height: 57px;
    text-align: center;
    position: absolute;
    top: 5%;
    left: 5%
}

.progress-circular.blue .progress-bar {
    border-color: #FF6C00;
}

.progress-circular.blue .progress-left .progress-bar {
    animation: loading-2 1.5s linear forwards 1.8s
}


@keyframes loading-1 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg)
    }
}



.first-time-onboarding {
    background-image: url('/public/images/onboarding-back-modal.png');
    background-position: right;
    background-repeat: no-repeat;
    padding: 2rem;
}
.first-time-onboarding h3 span {font-family: 'museo700';}
.onboarding-modal-content {
    width: 63%;
    font-size: 14px;
    color: #1A3A4C;
    margin: 20px 0px;
}
.theme-btn-dark {
    border: 0;
    background: #1A3A4C;
    color: #fff;
    padding: 4px 30px;
    border-radius: 10px;
}
.vertical-tab-notify {min-height: 100vh;}
.vertical-tab-notify .nav-pills{height: 100vh; background: #CDC4BA; width: 15%; padding-top: 30px;}
.vertical-tab-notify .nav-pills .nav-link.active, .nav-pills .show>.nav-link {color: #1A3A4C; background-color: #f1f1f1; border-radius: 10px 0px 0px 10px; font-family: 'museo700';}
.vertical-tab-notify .nav-pills .nav-link {color: #1A3A4C; padding: 0.5rem 1rem !important; margin-left: 15px; text-align: left;}
.vertical-tab-notify .nav-pills .nav-link i{margin-right: 7px;}
.vertical-tab-notify .tab-content {width: 100%;}
/* Mailbox */
.tab.mailbox-left-cont {
    float: left;
    background-color: #f1f1f1;
    width: 30%;
    height: 100vh;
    padding: 10px;
    overflow: auto;
  }
  
  .tab.mailbox-left-cont button {
    display: block;
    background-color: inherit;
    color: #1A3A4C;
    padding: 5px 10px;
    width: 100%;
    border: none;
    text-align: left;
    cursor: pointer;
    transition: 0.3s;
    font-size: 16px;
    border-bottom: 1px solid #cdc4ba;
  }

  .tab.mailbox-left-cont button:hover {
    background-color: #eee;
  }
  
  .tab.mailbox-left-cont button.active {
    background-color: transparent;
  }

.tabcontent {
    float: left;
    padding: 15px;
    width: 70%;
    border-left: none;
    height: 100vh;
    background: #FBF9F9;
}
.mailbox-user-msg {display: flex;}
.mailbox-user-msg li p{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    margin-bottom: 0;
    font-family: 'museo700';
}
.mailbox-user-msg li span{
    font-size: 15px;
}
.usernameinitial {
    background: #FF6464;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    color: #fff;
    justify-content: center;
    display: flex;
    align-items: center;
    font-family: 'museo700';
    font-size: 20px;
    display: inline-block;
    padding: 6px 8px;

}
.signature-sec {margin-top: 30px;}
.signature-sec label{display: block;}
.showmsghr p {font-family: 'museo700';}
.mailactioncenter {
    display: flex;
    clear: both;
    border-bottom: 1px solid #1a3a4c;
    justify-content: end;
    padding-bottom: 10px;
    margin-bottom: 10px;
}
.mailactioncenter i {
    background: #1A3A4C;
    border-radius: 50%;
    padding: 10px;
    color: #fff;
    margin-left: 3px;
    margin-right: 3px;
}
/* End Mailbox */

.refinefilter {display: flex;}
.refinefilter div.row:last-child {margin-left: 20px;}
.refinefilter .form-select {min-width: 180px;}
.droplabel {padding: 0; color: #1A3A4C; font-family: 'museo700'; padding-right: 10px;}
.dropselectinp {padding: 0; padding-right: 10px;}
.searchbtn-body{background: #1a3a4c; color: #fff; border-radius: 0 10px 10px 0px; padding: 0px 25px; border: 0;}
.inputsearch {min-width: 300px; border-radius: 10px 0px 0px 10px;}
.profile_inputsearch {min-width: 300px; border-radius: 4px 0px 0px 4px; background-color: #EFEFEF;height: 30px;}
.lable-heading {font-family: 'museo700'; color: #1a3a4c;;}
.section-sep {border-bottom: 1px solid #1a3a4c;}
.section-sep-border-non {border-bottom: 0px solid #1a3a4c;}
.card-footer-section p{margin: 10px 0px;}
.card-footer-section p span{font-family: 'museo700';}

ul.eventlist {list-style: none; padding-left: 0;}
ul.eventlist li{padding: 10px; border-radius: 10px; color: #1A3A4C; background: #CDC4BA; position: relative; border-left: 10px solid #807568; margin-bottom: 10px;}
ul.eventlist li i{position: absolute; right: 10px; top: 17px;width: 30px;height: 30px;background: #1a3a4c;color: #fff;border-radius: 50%;padding: 8px;font-size: 14px;display: flex;justify-content: center;}
ul.eventlist li p {font-family: 'museo700'; width: 90%;}

/* Notes Accordian */

.accordion-button.collapsed::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    transform: rotate(0deg);
}


.notesaccord .accordion-button {
    color: #191919;
    background-color: #CDC4BA;
    padding: .5rem 1.25rem .5rem 4.3rem;
}
.notesaccord .accordion-button::before {
    flex-shrink: 0;
    width: 3rem;
    height: 100%;
    content: "\f107";
    font-family: 'fontawesome';
    background-size: 1.25rem;
    transition: transform .2s ease-in-out;
    background-color: #807568;
    padding: 10px;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 10px 0px 0px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    color: #fff;
}
.notesaccord .accordion-button:not(.collapsed)::before {
    flex-shrink: 0;
    width: 3rem;
    height: 100%;
    content: "\f106";
    font-family: 'fontawesome';
    background-size: 1.25rem;
    transition: transform .2s ease-in-out;
    background-color: #807568;
    padding: 10px;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 10px 0px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    color: #fff;
}
.notesaccord .accordion-header {position: relative; display: flex; justify-content: center; align-items: center;}
.actionicons-notes {z-index: 1; position: absolute; right: 10px;}
.notesaccord .accordion-item {margin-bottom: 10px; background: transparent;}
.note-btn-container p {margin-bottom: 0;}
.note-btn-container span {font-family: 'museo500'; font-size: 13px;}
.notesaccord .accordion-body { padding: 1rem 1.25rem;background: #fff;border-radius: 0px 0px 10px 10px; min-height: auto;}
.notesaccord .accordion-item .accordion-button.collapsed {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}
/* End Notes Accordian */

/* Course Page */
.coursepage {
    background-image: url(/public/images/course-banner.png);
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    height: 247px;
    background-color: #131111;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 2.3rem;
}
.coursepage h1 {font-size: 60px; font-family: 'museo100';}
.course-left-sec {background-color: #F1F1F1; min-height: 100vh; padding: 50px 30px;}
.course-right-sec {background-color: #fff; padding: 3rem 4rem;}
.profile-right-sec {background-color: #FFF;}
.course-cards img.img-fluid {border-radius: 10px 10px 0px 0px;}
.course-cards {margin-top: 30px !important;}
.img-videosec {position: relative;}
 .img-fluid{width: 100%;}
.img-video_pro {position: relative;}
.img-video_pro img {border-top-right-radius: 10px; border-top-left-radius: 10px;}
.img-videosec video {border-radius: 10px 10px 0px 0px;}
.premium-tag {
    position: absolute;
    left: 5px;
    top: 5px;
    background: #1a3a4c;
    border-radius: 5px;
    padding: 2px 10px;
    color: #fff;
    font-size: 12px;
}
.premium-tag img{height: 14px; margin-right: 5px;}
.left-radio-heading {font-size: 22px; font-family: 'museo100';  width: 100%; margin-bottom: 20px;}
.course-left-sec .form-check {margin-bottom: .8rem;}
.course-head-al {color: #1A3A4C; text-decoration: none;margin-right: 6px;}
.course-head-al:hover {color: #084e77; text-decoration: none;}

.modal-top-head {background: #CDC4BA; border-radius: 5px 5px 0px 0px; display: flex; justify-content: space-between; align-items: center; border-left: 60px solid #807568; padding: 5px 20px;}
.course-popup-head {font-family: 'museo700'; margin-bottom: 0; font-size: 20px;}
.savetomynote-btn {
    background: #1a3a4c;
    color: #fff;
    font-size: 1rem;
    font-family: 'museo500';
    border: 0;
    border-radius: 5px;
    padding: 0.2rem 1rem;
    width: 100%;
}
.premium-bg-pop {
    background-image: url(/public/images/course-premium-popback.jpg);
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 2rem;
    color: #1A3A4C;
}
.dark-outline {
    background: transparent;
    color: #1A3A4C;
    padding: 4px 30px;
    border-radius: 10px;
    border: 1px solid #1A3A4C;
    /* display: block; */
    margin-top: 10px;
    font-family: 'museo700';
}
.add-more-icon {font-size: 30px !important; vertical-align: middle;}
.popup-head-bold {font-family: 'museo700';}
/* End Course Page */
/* Plan And Pricing */
.planandpricing {
    background-image: url(/public/images/plan-pricing.png);
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    height: 247px;
    background-color: #131111;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 2.3rem;
}
.planandpricing h1 {font-size: 60px; font-family: 'museo100';}
.showpricelist {margin: 40px 0px;}
.price-list { margin: 0 20px; box-shadow: 0px 0px 20px #00000066; border-radius: 10px; padding: 0;}
.basic-plan .price-list-head {background: #FF6464; color: #fff; border-radius: 10px 10px 0px 0px; position: relative; padding: 20px; min-height: 110px;}
.premium-plan .price-list-head {background: #FF6C00; color: #fff; border-radius: 10px 10px 0px 0px; position: relative; padding: 20px; min-height: 110px;}
.price-list-head .price-cots {font-size: 50px; font-family: 'museo700'; line-height: 1;}
.forever-monthly-plan{font-size: 14px; text-align: center; font-family: 'museo100'; margin-bottom: 0;}
.befortprice {font-size: 16px; vertical-align: super;}
.price-desc-right {position: absolute; margin-left: 20px;}
.price-desc-right span{font-family: 'museo100';}
.price-desc-right .plan-desc{font-family: 'museo700'; margin-bottom: 0;}
.check-list-price {padding: 20px 30px; color: #1A3A4C;}
.check-list-price li{margin-bottom: 10px;}
.check-list-price i{font-size: 22px; margin-right: 20px; font-weight: 100;}
.check-list-price i.fa-check{color: #00B70E;}
.check-list-price i.fa-times{color: #E5E5E5;}
.price-get-btn {    
    background: #1a3a4c;
    color: #fff;
    font-size: 13px;
    font-family: 'museo500';
    border: 0;
    border-radius: 5px;
    padding: 0.2rem 1rem;
}
.btn-dis {opacity: .5;}
.price-icon-ind {position: absolute; top: -25px; left: -25px;}
/* End Plan And Pricing */
/* video screen */
.videopage {
    background-image: url(/public/images/video-banner.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 247px;
    background-color: #131111;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 2.3rem;
}
.videopage h1 {font-size: 60px; font-family: 'museo100';}
/* End video screen */
/* Certificat */
.certifybanner {
    background-image: url(/public/images/certificat-banner.png);
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    height: 247px;
    background-color: #131111;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 2.3rem;
}
.productsbanner {
    background-image: url(/public/images/products_img.jpg);
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    height: 247px;
    background-color: #131111;
     color: #1a3a4c;
    display: flex;
    align-items: center;
    padding: 2.3rem;
}


.productsbanner h1 {
    font-size: 60px;
    font-family: 'museo100';
}
.certifybanner h1 {font-size: 60px; font-family: 'museo100';}

.certificat-container{
    width: calc(100% - 65px);
    margin: 0 auto;
    padding: 40px 0px;
}
.activation-container{
    
        width: calc(100% - 114px);
        margin: 0 auto;
        /* padding: 40px 0px; */
    }
    .act h5{
        color: #1a3a4c;
        font-size: 20px;
        width: 320px;
    }
    
      
      

    .act h3{
        color:#1a3a4c;
        font-size: 30px;

    }

    .userEmail p{
        font-weight: 600;
        margin-bottom: 5px;
        font-size: 14px;

    }
    .act p{
        margin-bottom: 5px;

    }

    .act-john-id h5{
        color: #1a3a4c;
        padding-left: 12px;
    }
    .subscribe_img{
        background-image: url(/public/images/subscribe_imgg.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 340px;
        margin-bottom: 200px;

    }

    .subscribe_content {
        padding: 30px 0 0 30px;
        margin-bottom: 200px;
    }
    .subscribe_content p{
        padding: 15px 0 0 0px;
        width: 73%;
        color: #000;
        line-height: 20px;
    }

    .subscribe_content button {
        padding-top: 5px;
        text-align: center;
        width: 66%;
        border-radius: 5px;
        margin-top: 20px;
        color: #fff;
        background-color: #1a3a4c;
        font-size: 15px;
        height: 33px;
        margin-top: 0;
      
        border: none;
    }

    .act_link{
        margin-bottom: 170px;
    }


    .resend_link button{
        padding-top: 4px;
        text-align: center;
        width: 168px;
        border-radius: 5px;
        margin-top: 20px;
        color: #1a3a4c;
        background-color: #fff;
        border: 2px solid #1a3a4c;;
        font-size: 16px;
        height: 32px;
        margin-top: 0;
        font-weight: 600;
    }
    .resend_link a{
        color: #FF6464;
        font-size: 14px;
        text-decoration: none;
    }


    .resend_link{
        margin-top: 50px;
    }

.back-dark-heading {
    background: #1A3A4C;
    width: 100%;
    padding: 10px;
    margin-bottom: 1rem;
    color: #63C3DD;
    margin-top: 1rem;
}
/* .right-sec-detailsshow {padding-top: 89px;} */
.right-sec-detailsshow ul {padding-left: 0; list-style: none; margin-bottom: 5px; width: 49%; vertical-align: top; display: inline-block;}
.right-sec-detailsshow ul li:first-child{background: #FF6464; color: #1A3A4C;font-family: 'museo700'; padding: 6px 15px;}
.right-sec-detailsshow ul li:last-child{background: #E5E5E5; color: #1A3A4C;padding: 6px 15px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
.registor-online-btn {
    background: #1a3a4c;
    color: #fff;
    font-size: 1rem;
    font-family: 'museo500';
    border: 0;
    border-radius: 5px;
    padding: 0.2rem 1rem;
    width: 100%;
}

.right-sec-detailsshow{
    overflow-x: auto;
    /* width: 50%; */
}
/* End Certificat */

.slide-toggle {display: none !important;}

.acc_custm span{
    margin-top: 8px;
}



/* --ra--start---fintrac--- */

.eBlast_backg_img {
    background-image: url(/public/images/discoverimg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100px;
}

.head-back-top h3 {
    margin-bottom: 0;
    color: #fff;
    margin-left: 10px;
}

.course-details-icons i {
    width: 30px;
    height: 30px;
    background: #1a3a4c;
    color: #fff;
    border-radius: 50%;
    padding: 8px;
    font-size: 14px;
}

.wlc_eblast_icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
}
.head-back-top {
    display: flex;
}

.finish-block-btn {
    background: #1a3a4c;
    border: 0;
    color: #fff;
    width: 100%;
    padding: 12px;
    margin-top: 30px;
}

/* .details-course-desp h5 {
    font-family: 'museo700';
    margin: 15px 0px;
} */

.next-course-btn {
    border-radius: 5px;
    padding: 6px 15px;
    width: 180px;
    display: flex;
    justify-content: space-between;
    background: #1a3a4c;
    border: 1px solid #1a3a4c;
    color: #fff;
    float: right;
    align-items: center;
}

.details-course-desp h5 {
    font-family: 'museo700';
    margin: 30px 0px 0px 0px;
    /* margin-bottom: 0; */
}

.img_fluid_cust {
    width: 100% !important;
    max-width: 100% !important;
    border-radius: 10px;
}

.certificate-section {
    text-align: center;
    padding: 30px;
}

.img-box {
    box-shadow: 0px 0px 5px 5px #e2e1e1;
    border-radius: 10px;
}

.recommendationsec {
    width: calc(100% - 100px);
    margin: 0 auto;
    padding: 20px 0px;
}

.detail-page-container {
    width: calc(100% - 50%);
    margin: 0 auto;
}


/* --ra--end---fintrac--- */



/* --ra--start---descovery-taxt-article--- */


.progress.inprogress-bar-color .progress-bar {
    background-color: #FF6C00;
}

.progress.completed-bar-color .progress-bar {
    background-color: #00B70E;
}


.course-tile-heading i {
    color: #FF6464;
    margin-left: 5px;
}

.continue-btn {
    background: #1a3a4c;
    border: 1px solid #1a3a4c;
    color: #fff;
}


.course-button {
    border-radius: 5px;
    padding: 2px 15px;
    /* position: absolute; */
    /* bottom: 18px; */
    width: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.revisit-btn {
    border: 1px solid #1a3a4c;
    background: #fff;
    color: #1a3a4c;
}

.locked-btn {
    background: #B1B1B1;
    border: 1px solid #B1B1B1;
    color: #fff;
}

.course-tile-heading {
    font-family: 'museo700';
    padding-left: 24px;
}

.progress.inprogress-bar-color {
    height: 0.5rem;
}

.progress.completed-bar-color .progress-bar {
    height: 0.5rem;
}




/* --ra--end---descovery-taxt-article--- */

.cc-bg-colorr button {
    width: 60px;
    height: 18px;
    position: relative;
    bottom: 31%;
    font-size: 10px;
    left: 22%;
    text-align: center;
    border-radius: 5px;
     padding: 0;
    border: 2px solid  #1A3A4C;
    background-color: #1A3A4C;
    color: #fff;
}


/* home-banner */

/* .banr_3 p:last-child{
    font-weight: 600;
} */
/* home-banner */

.img-videosec_img{
    width: 100%;
}


.container-chart .box canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    width: 100%;
}

.container-chart .box .chart-2 i {
    font-size: 22px;
}

.accordion-item {
    background-color: #fff;
    border: 0;
}


/* element.style {
} */
.container-chart .box .chart-2 {
    position: relative;
    width: 80%;
    height: 80%;
    text-align: center;
    font-size: 13px;
    line-height: 17px;
    color: #1A3A4C;
    padding: 20px 15px;
    font-family: 'museo700';
}

.container-chart .box {
    width: 100%;
}


.dflx {

    position: relative;
    bottom: 32px;
    /* margin-bottom: 50px; */
    left: 9%;
}




/* ---start--Tanvi _Hamburger */
button#openbtn {
    width: 31px;
    height: 32px;
    background: #1a3a4c;
    color: #fff;
    border-radius: 50%;
    padding: 8px;
    font-size: 14px;
    border: none;
}

#mySidenav .nav-tabs .nav-item button:hover,
#mySidenav .nav-tabs .nav-item button:focus,
#mySidenav .nav-tabs .nav-item button:focus-visible,
#mySidenav .nav-tabs .nav-item button.active {
    background: transparent;
    border: none;
    border-bottom: 2px solid #47b0e4;
} 

#mySidenav .nav-tabs .nav-item button {
    font-size: 15px;
    padding: 5px 5px !important;
    color: #fff;
} 

#mySidenav .tab-content .tab-pane h4 {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: #fff;
}

#mySidenav .tab-content .tab-pane#modules .modules-menu .modules-menu-item {
    padding: 10px 0;
}

#mySidenav .tab-content .tab-pane {
    padding: 20px 15px;
}


#mySidenav .tab-content .tab-pane#modules .modules-menu .modules-menu-item .title {
    font-size: 16px;
    line-height: 1.5;
}

button#openbtn {
    width: 31px;
    height: 32px;
    background: #1a3a4c;
    color: #fff;
    border-radius: 50%;
    padding: 8px;
    font-size: 14px;
    border:none;
}


#mySidenav .tab-pane .jba-toolkit-module {
    padding: 10px 0px;
}
#mySidenav .tab-pane .jba-toolkit-module .jba-toolkit-module-item {
    padding: 5px 0px;
    display: flex;
    justify-content: space-between;
}
#mySidenav .tab-pane .my-favorites-item {
    display: flex;
    justify-content: space-around;
    padding: 10px 0px;
    color: #fff;
}
#mySidenav .tab-pane .notes-item .item-info-wrapper {
    display: flex;
    padding: 10px 0px;
}



#mySidenav .nav-tabs {
    flex-wrap: nowrap;
    justify-content: center;
    margin: 0 10px;
} 

#mySidenav .tab-content .tab-pane#modules .modules-menu .modules-menu-item .title {
    font-size: 16px;
    line-height: 1.5;
}

#mySidenav.sidenav.open {
    width: 300px;
    /* border-radius: 50%; */
}

#mySidenav.sidenav a:hover {
    color: #f1f1f1;
  }
  
  #mySidenav.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }

  #mySidenav.sidenav a {
    padding: 6px 4px 5px 0px;
    text-decoration: none;
    font-size: 10px;
    color: #111111;
    display: block;
    transition: 0.3s;
}


#mySidenav.sidenav {
    height: 100%;
    width: 0px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: rgb(26 58 76);
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 120px;
  }

  a.card-link {
    margin-left: 10px;
}
  
.values{position: absolute; display: block; left:32px; top:-135px; font-size: 30px !important;}
.incomplete{position: absolute; display: block; right:32px; top:-135px; font-size: 12px !important; text-align: center; }
/* --end--Tanvi _Hamburger */

/* @media (min-width: 768px) and (max-width: 1199.98px) {
    
    .chart-two svg .circle-background, .chart-two svg .circle-foreground {
        r: 45.5px;
        cx: 50%;
        cy: 50%;
        fill: none;
        stroke: #305556;
        stroke-width: 5px;
      }
      .values{left:28px;  }
      .incomplete{right:8px;  }
    

} */

/* .btn_home li{
    display: inline-block;

    

} */

.view-button{
    display: flex;
    justify-content: space-between;
}


.view-button button {

/* bottom: 10px; */
    width: 9%;
    border-radius: 20px;
    margin-bottom: 10px;
    color: white;
    background-color: #1a3a4c;
    border: none;
    font-size: 12px;

}

/* ---start--on-boarding */
.cardBoadgimg{

    margin-bottom: 7px;
}




.container-chart .box canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    width: 100%;
}

.container-chart .box .chart-2 {
    position: relative;
    width: 106px;
    height: 80%;
    text-align: center;
    font-size: 13px;
    line-height: 17px;
    color: #1A3A4C;
    padding: 20px 15px;
    font-family: 'museo700';
}

.container-chart .box .chart-1 {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 30px;
    line-height: 50px;
    height: 130px;
    color: #1A3A4C;
    padding: 27px;
    font-family: 'museo700';
}


.finishit-btn {
    margin: 0px 0px 0px 20px;
}
/* end- Ronie Woodkin */


/* start Roleplay */

.view-buttonRolePlay{
         display: flex;
        justify-content: space-between;
    }


.view-buttonSave{
         display: flex;
        justify-content:flex-end;
    }
.view-buttonAdd_commt{
    display: flex;
        justify-content:flex-end;
        }


.view-buttonFeedback{
    display: flex;
        justify-content:flex-end;
        }



.view-buttonRolePlay button {
    width: 32%;
    height: 33px;
border-radius: 5px;
margin-bottom: 10px;
color: white;
background-color: #1a3a4c;
border: none;
font-size: 12px;
/* text-align: center; */
}

.view-buttonAdd_commt {
    float: right;
        
        }


.view-buttonAdd_commt button {
    width: 200px;
    height: 33px;
border-radius: 5px;
margin-bottom: 10px;
color: white;
background-color: #1a3a4c;
border: none;
font-size: 12px;
margin-left: 10px;
text-align: center;
}


.view-buttonFeedback button {
    width: 200px;
    height: 33px;
border-radius: 5px;
margin-bottom: 10px;
color: white;
background-color: #1a3a4c;
border: none;
font-size: 12px;
margin-left: 10px;
text-align: center;
}


/* .view-buttonAdd_commt button {
    text-align: center;
   
    padding: 8px 40px;
} */


.view-buttonSave button {
    width: 40%;
    height: 33px;
border-radius: 5px;
margin-bottom: 10px;
color: white;
background-color: #1a3a4c;
border: none;
font-size: 12px;
}

.left-sec-RolePlay{

    margin-top: 82px;
}

.record-btn-img img{
    margin-right: 10px;
}



.text_areaCustom{

    height: 300px;
}

.input-labl{
    color: #1a3a4c;
}
.input-labl span{
    color: #1a3a4c;
}

.door_knoc_vidCont h6 {
    display: inline-block;
}
.door_knoc_vidCont p {
    display: inline-block;
    font-size: 16px;
    
}

.door_knoc_vidCont{

    height: 622px;
}
.door_knoc_vidCont h6{

    color: #1a3a4c;
}

.door_knoc_vidCont2{

    height: 400px;
}
/* afreen css*/
.RolePlay-Participate{height: 153px;}
.course-left-secParticipateRP {
    background-color: #fff;
    min-height: 100vh;
    /* padding: 80px 27px; */
    padding: 74px 0px;
}

.profile-left-secParticipateRP {
    background-color: #fff;
    min-height: 100vh;
    padding: 0 27px;
    /* padding: 74px 16px; */
}

.profile-left-sec {
    background-color: #fff;
    min-height: 100vh;
    padding: 0 0 0 61px;
}


.rolllply p{
    margin-bottom: 0px;
}

.backGround_colr{
    background-color: #ebebeb;
}


.cont_custt{
    width: calc(100% - 30px);
}

.progress-bar1{
    background-color:#1a3a4c; 
    width: 30% !important;
}
.progress-bar2{
    background-color:#1a3a4c; 
    width: 100% !important;
}
.img-master_card {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #eaeaea;

}


.details-cards-oll1 {
    padding: 5px 15px 20px;
    background: #eaeaea;
    border-radius: 0px 0px 10px 10px;
    margin-bottom: 5px;
}

.details-cards-oll1 .progress {
    height: 5px;
    background-color: #E5E5E5;
    border-radius: 5px;
}

.card-width_cust{
    width: 19% !important;
}


.btn-agree-submit button{

        width: 18%;
        height: 33px;
        border-radius: 5px;
        margin-bottom: 10px;
        color: white;
        background-color: #1a3a4c;
        border: none;
        font-size: 16px;
        margin-right: 5%;
        /* text-align: center; */
    }

    .color_main{
        color:#1a3a4c !important;
    }

    .color_main p{

        color: #1a3a4c;
    }


    .masterofethics {
        background-image: url(/public/images/codeofetetics.png);
        background-position: right;
        background-repeat: no-repeat;
        background-size: cover;
        height: 247px;
        background-color: #131111;
        color: #fff;
        display: flex;
        align-items: center;
        padding: 2.3rem;
    }
    .masterofethics h1 {font-size: 60px; font-family: 'museo100';}
    .roleplay h1 {font-size: 60px; font-family: 'museo100';}
    .myroleplay h1 {font-size: 60px; font-family: 'museo100';}
    .partyroleplay h1 {font-size: 60px; font-family: 'museo100';}
    .reviewroleplay h1 {font-size: 60px; font-family: 'museo100';}
    .profile_img h1 {font-size: 60px; font-family: 'museo100';}


    .roleplay {
        background-image: url(/public/images/roleplay_img.jpg);
        background-position: right;
        background-repeat: no-repeat;
        background-size: cover;
        height: 247px;
        background-color: #131111;
        color: #fff;
        display: flex;
        align-items: center;
        padding: 2.3rem;
    }
    .myroleplay{
        background-image: url(/public/images/roleplay_img.jpg);
        background-position: right;
        background-repeat: no-repeat;
        background-size: cover;
        height: 247px;
        background-color: #131111;
        color: #fff;
        display: flex;
        align-items: center;
        padding: 2.3rem;
    }
    .partyroleplay{
        background-image: url(/public/images/roleplay_img.jpg);
        background-position: right;
        background-repeat: no-repeat;
        background-size: cover;
        height: 247px;
        background-color: #131111;
        color: #fff;
        display: flex;
        align-items: center;
        padding: 2.3rem;
    }
    .profile_img{
        background-image: url(/public/images/profile_header_img.jpg);
        background-position: right;
        background-repeat: no-repeat;
        background-size: cover;
        height: 120px;
        background-color: #131111;
        color: #fff;
        display: flex;
        align-items: center;
        padding: 2.3rem;
    }
    .network_img{
        background-image: url(/public/images/network_banner_img.jpg);
        background-position: right;
        background-repeat: no-repeat;
        background-size: cover;
        height: 120px;
        background-color: #131111;
        color: #fff;
        display: flex;
        align-items: center;
        padding: 2.3rem;
    }

    .network_searchbtn-body {
        background: #fff;
        color: #1a3a4c;
        border-radius: 0 4px 4px 0;
        padding: 0px 12px;
        border:1px solid #1a3a4c;
        height: 30px;
    }


    .network_img h1{
        color: #FF6464;
        padding-left: 18px;
    }

    .network_img h1 {
        font-size: 60px;
        font-family: 'museo100';
    }
    .reviewroleplay{
        background-image: url(/public/images/roleplay_img.jpg);
        background-position: right;
        background-repeat: no-repeat;
        background-size: cover;
        height: 247px;
        background-color: #131111;
        color: #fff;
        display: flex;
        align-items: center;
        padding: 2.3rem;
    }

    /* end- Roleplay  */
    
    /* .note-btn-container p, span{
        color: #1a3a4c;
    } */



   

    



/* ---start--dashBoard */
button.btnDetails {
    width: 18%;
    height: 24px;
    font-size: 9px;
    border-radius: 5px;
    background-color: #1A3A4C;
    color: #fff;
    border: none;
    border-bottom: none;
    padding: 7px;
   
}

.detail_data{
    border-bottom: none;
}


.tble_body{
    border-top: 0px !important;
}

.color_ws{
    color: #1A3A4C;;
}

img.image {
    height: 160px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
}



.certify {
    height: 140px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.font_wt{
    font-weight: lighter;
}

h3 {
    font-weight: bold;
    color: #fff;
}

.point {
    display: flex;
    justify-content: space-between;
}


/*
.point p {
    padding-right: 217px;
}
*/
.point p {font-weight: 800;}

.details {
    display: flex;
    margin-top: 5px;
}

.elearn h6 {
    width: 14%;
    margin-left: 0px;
}


.progress-barr {
    border-radius: 30px;
    background-color: #63c3dd;
}

/* h5 {
    font-weight: bold;
    color: #1A3A4C;
    font-size: 15px;
} */

/* p {
    color: #1A3A4C;
    margin-bottom: 0px;
} */

.icon1 {
    font-size: 20px;
}

.icon {
    color: #1A3A4C;
    font-size: 30px;
    padding-left: 17px;
}

.learn {
    width: 100%;
    margin-left: 10px;
    margin-top: 5px;
}

.elearn {
    display: flex;
    justify-content: space-between;
    /*column-gap: 300px;*/
}

.icon1 {
    font-size: 20px;
}

.icon {
    color: #1A3A4C;
    font-size: 30px;
    padding-left: 17px;
}

.learn {
    width: 100%;
    margin-left: 10px;
    margin-top: 5px;
}

/* .elearn {
    display: flex;
    justify-content: space-between;
    column-gap: 300px;
  
} */

.icon1 {
    font-size: 20px;
}

.icon {
    color: #1A3A4C;
    font-size: 30px;
    padding-left: 17px;
}

.learn {
    width: 100%;
    margin-left: 10px;
    margin-top: 5px;
}

/* .elearn {
    display: flex;
    justify-content: space-between;
    column-gap: 300px;
} */

.detailbtn {
    margin-top: 10px;
}

.icon1 {
    font-size: 20px;
}
.icon {
    color: #1A3A4C;
    font-size: 30px;
    padding-left: 17px;
}

.learn {
    width: 100%;
    margin-left: 10px;
    margin-top: 5px;
}

/* .elearn {
    display: flex;
    justify-content: space-between;
    column-gap: 300px;
   
} */

.detailbtn {
    margin-top: 10px;
}

.col {
    background-color: white;
    border-radius: 20px;
    padding: 10px;
    margin: 0px 10px 10px 0px;
}

.ttxt p{
    color: #fff;
}

/* h5 {
    font-weight: bold;
    color: #1A3A4C;
    font-size: 15px;
} */

.header {
    margin-top: 15px;
    text-align: center;
    color: #1A3A4C;
    font-family: Roboto;
}

/* p {
    color: #1A3A4C;
    margin-bottom: 0px;
} */

.icons {
    text-align: center;
    color: #1A3A4C;
    font-size: 25px;
}

/* .row {
    
    margin: 0;
} */

.col {
 
    background-color: white;
    border-radius: 20px;
    padding: 10px;
    margin: 0px 10px 10px 0px;
}

/* h5 {
    font-weight: bold;
    color: #1A3A4C;
    font-size: 15px;
} */

.icon {
    color: #1A3A4C;
    font-size: 30px;
    padding-left: 17px;
}

content {
    display: flex;
    justify-content: flex-start;
    color: #1A3A4C;
    padding: 5px;
}

.icon1 {
    font-size: 20px;
}




.learn {
    width: 100%;
    margin-left: 10px;
    margin-top: 5px;
}


/* .elearn {
    display: flex;
    justify-content: space-between;
    column-gap: 300px;
   
} */


.content {
    display: flex;
    justify-content: flex-start;
    color: #1A3A4C;
    padding: 5px;
}

.arrow {
    display: flex;
    justify-content: space-around;
    font-size: 35px;
    color: #1A3A4C;
}


.color_wht{
    color: #fff;
}

.tble_body tr td{
    color: #1A3A4C;
}


.earnd h3,p{
    color: #1A3A4C;
    font-family: 'museo500';

}

.atten_pop p {
    font-size: 13px;
}

.pro_count p,span{
    color: #fff;
}
/* ---end--dashBoard */

.atten_pop p{
    color: #fff;
}


.eventlist  li span{
    color: #1A3A4C;
} 

.color_main span{
    color: #1A3A4C;
}

.cou_card p:nth-child(2){
    padding-bottom: 5px;
}
.coud_card p:nth-child(2){
    display: flex;
    flex-direction: column;
    padding-bottom: 5px;
}


.more-card button {
    width: 60px;
    height: 20px;
    /* position: relative; */
    /* bottom: 31%; */
    font-size: 10px;
    /* left: 22%; */
    text-align: center;
    border-radius: 5px;
     padding: 0;
    border: 2px solid  #1A3A4C;
    background-color: #1A3A4C;
    color: #fff;
}



.colr_blkk{
    color: #000;
}
/* .colr_blkk tr td i{
    color: #000;
} */
.colr_blkk tr span{
    color: #000;
}

/* .network_Viewall_btn button{

    width: 60px;
    height: 20px;
   
    font-size: 10px;
    text-align: center;
    border-radius: 5px;
     padding: 0;
    border: 2px solid  #1A3A4C;
    background-color: #1A3A4C;
    color: #fff;
    margin-top: 20px;

} */

.network_Viewall_btn button{
    /* bottom: 10px; */
    width: 100px;
    border-radius: 20px;
    margin-top: 20px;
    color: white;
    background-color: #1a3a4c;
    border: none;
    font-size: 12px;
    height: 30px;
}

.network_btn button{
    width: 100px;
    border-radius: 20px;
    margin-top: 20px;
    color: white;
    background-color: #1a3a4c;
    border: none;
    font-size: 12px;
    height: 30px;
    margin-top: 0;

}
.profile_btnn button{
    padding-top: 5px;
    text-align: center;
    width: 22%;
    border-radius: 5px;
    margin-top: 20px;
    color: #1a3a4c;
    background-color: #fff;
    border: 2px solid;
    font-size: 15px;
    height: 37px;
    margin-top: 0;
    font-weight: 600;
}

.input_bg{
    background-color: #EFEFEF;
    height: 30px;
}

.profile_content p{
    font-size: 14px;
    color: #000;
    width: 49%;
}



.more-card img{
    position: absolute;
    /* top: 0px; */
    right: 7px;

}

.network_more-card img{
    position: relative;
    top: 11px;
    left: 75px;

}

/* .more-card {float: right; padding-top: 20px;} */
.network_more-card {float: right; }



/*Certify- modification  */

.tble_bdy{
    border-top: 0px !important;
}

.thead_bdy tr th{
    width: 100px;
    height: 30px;
    background-color: #FF6464;
    color:#fff;
    border-right: 1px solid #fff;
}

.tble_bdy tr td{
    width: 100px;
    height: 63px;
    background-color: #E5E5E5;
    color: #1a3a4c;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
}
.tble_bdy tr td a{
    width: 100px;
    height: 50px;
    background-color: #E5E5E5;
    color: #1a3a4c;
    list-style: none;
}


.accordion-body_certify {
    padding: 1rem 1.25rem;
    background: #fff !important;
    border-radius: 0px 0px 10px 10px;
    min-height: 100vh;
}

.accordion-button_certify:not(.collapsed) {
    color: #1a3a4c;
    background-color:#fff !important;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

.accordion-button_certify {
    color: #1a3a4c;
    background-color: #fff;
    border: 1px solid #1a3a4c;
}


.accordion-button_certify::after {
  
        /* flex-shrink: 0; */
        width: 1.5rem;
        height: 1.5rem;
    /* width: 1.5rem; */
    /* height: 1.5rem; */
    border-radius: none !important;
    padding: 0px;
}

.accordion-item:first-of-type .accordion-button_certify {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    font-family: 'museo700';
    margin-top: 0px;
}

.thead_bdy:first-child{
    width: 100px;
    height: 30px;
    background-color: #FF6464;
    color:#fff;
    border-right: 1px solid #fff;
}
/* .thead_bdy_border tr td{
 
    padding-bottom: 10px;
} */
/*Certify- modification  */

    /* bottom: 10px; */


    .addTocart_btn button{
        width: 100%;
        border-radius: 5px;
        /* margin-top: 25px; */
        color: white;
        background-color: #1a3a4c;
        border: none;
        font-size: 12px;
        height: 30px;
        /* margin-left: 64px; */
        padding: 0px 0px;
        position: relative;
        top: 0;
        left: 0%;
    
}


.first-time-product h3 span{
    font-family: 'museo700';
    color: #1a3a4c;
    padding-bottom: 10px;
    

    
}

.org_col{
    color: #ff7c1c;
}

.premium-bg-product {
    height: 480px;
}

.right-sec-product{
    display: flex;
    justify-content: space-between;
    background: #1A3A4C;
    width: 100%;
    padding: 2px 10px;
    margin-bottom: 16px;
    /* color: #63C3DD; */
    /* margin-top: 1rem; */

}

.dollor{
    color: #fff;
    font-size: 18px;
    margin-bottom: 0px;
    padding-top: 8px;
}


.back-dark-product{
    display: flex;
    justify-content: center;
}

/* .back-dark-product {
    background: #1A3A4C;
    width: 100%;
    padding: 14px;
    margin-bottom: 1rem;
    color: #fff;
    margin-top: 1rem;
} */
.back-dark-product {
    background: #1A3A4C;
    width: calc(100% - 20px);
    padding: 14px;
    /* margin-bottom: 1rem; */
    color: #fff;
    /* margin-top: 1rem; */
    margin: 15px auto;
    border-radius: 5px;
    }

    .policy_txt{
       
            width: calc(100% - 24px);
            margin: 0 auto;
       
    }

    .policy_txt p{
       
          font-size: 12px;
    }




.tble_bdy_product tr td {
    width: 100px;
    color: #1a3a4c;


    
}
.table>:not(caption)>*>* {
    padding: 0.5rem 0.5rem;
    background-color: var(--bs-table-bg);
    /* border-bottom-width: 0px !important; */
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.thead_bdy_product tr th {
   
    color: #1a3a4c;
    
}

.billed{
    font-size: 8px;
    font-weight: 100;
}

.product_tb_bg{
    background-color: #e8ebed;
    padding: 0;

} 


.star h5{
    display: inline-block;
    /* font-size: 14px; */
    color: #1A3A4C;
}
.star p{
    display: inline-block;
    /* font-size: 14px; */
    color: #1A3A4C;
}

.course_content{
    /* width: 63%; */
    font-size: 14px;
    color: #1A3A4C;
    margin: 20px 0 0;
}
.course_content h5{
   
    margin-bottom: 0px;
}

.table_summary{

    overflow-x:auto;
    width: calc(100% - 20px);
    margin: 0 auto;

}


.larng_point{
    overflow-x: auto;
}

.abc{
    width: calc(100% - 20px);
    margin: 0 auto;
}

.upgrate-bnrbtn a{
    text-decoration: none;

}




.pop_modal_body{
    /* background-color: #1a3a4c; */
    width: 100%;
    /* height: 200px; */
   
    color: #000;

}

.pop_modal_body h4{
   
   
    color: #000;
    /* padding: 10px 0 0 10px; */

}
.pop_modal_body p{
    /* background-color: #1a3a4c;
    width: 100%;
    height: 200px; */
   
    color: #000;
    /* padding-left: 10px; */
    font-family: 'museo500' ;

}

.forget_btn button{
    width: 172px;
    border-radius: 5px;
    /* margin-top: 20px; */
    color: white;
    background-color: #1a3a4c;
    border: none;
    font-size: 14px;
    height: 36px;
    margin-top: 17px;
    text-align: center;
    padding-top: 5px;
}
.forget_btn button:hover{
  border: 1px solid #1a3a4c;;
    color: #1a3a4c;
    background-color: #fff;
 
}
.forget_btn p{
   padding: 50px 0 0 0;
    /* margin-top: 20px; */
    color: #000;
  
}
.forget_btn p a{
 
    color: #000;
  
}

.backGround{
    margin: 0 0 0 0 !important;
}


/* pop-forget password */

.video_play a{
    text-decoration: none;
    color: #1a3a4c;
    font-size: 18px;

}
.video_play {
    text-decoration: none;
    color: #1a3a4c;
    font-size: 18px;

}


.fav_img {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.product_recom{
       padding: 5px 15px 20px;
    background: #fff;
    border-radius: 0px 0px 10px 10px;
    box-shadow: 2px 2px 5px grey;
    margin-bottom: 5px;
    height: 153px;
}


.certify_mg{
    /* margin-top: 89px; */
    background-color: #1a3a4c;
   
}

.cert_colr a{
    color: #63C3DD;

}

.navv{
    padding: 0 !important;
}

.navbar-nav .dropdownItem {
    color: #fff;
    padding: 10px 85px 10px 20px;
    align-items: center;
    /* justify-content: left; */
    display: flex;
}

/* .dd_arrow i{
    padding-left: 200px !important;
} */

/* .dropdown-menu .showw {
    display: block;
    width: 100% !important;
} */



.edit_profile{
    font-family: 'museo700';
    color: #1a3a4c;
    padding: 9px 0 0px 40px;
    margin-bottom: 0;
}


.edit_profile_input{
    /* width:calc(100% - 78px); */
    margin: 0 auto;
}
.edit_btn button{
width: 100px;
border-radius: 20px;
margin-top: 20px;
color: white;
background-color: #1a3a4c;
border: none;
font-size: 12px;
height: 30px;
margin-top: 0;
}

.input-group img{
    cursor:pointer;
}

.headg_light {
    color: #707070;
    font-weight: 500;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #707070;
}





.required label:after { content:"*";color: red;}


/*Profile Pic Start*/
.picture-container{
    position: relative;
    cursor: pointer;
    text-align: center;
}
.picture{
    width: 106px;
    height: 106px;
    background-color: #999999;
    border: 4px solid #CCCCCC;
    color: #FFFFFF;
    border-radius: 50%;
    margin: 0px auto;
    overflow: hidden;
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
}
.picture:hover{
    border-color: #2ca8ff;
}
.content.ct-wizard-green .picture:hover{
    border-color: #05ae0e;
}
.content.ct-wizard-blue .picture:hover{
    border-color: #3472f7;
}
.content.ct-wizard-orange .picture:hover{
    border-color: #ff9500;
}
.content.ct-wizard-red .picture:hover{
    border-color: #ff3b30;
}
.picture input[type="file"] {
    cursor: pointer;
    display: block;
    height: 100%;
    left: 0;
    opacity: 0 !important;
    position: absolute;
    top: 0;
    width: 100%;
}

.picture-src{
    width: 100%;
    
}

.upload_img{
    color: #fff;
    padding-top: 16px;
}
/*Profile Pic End*/

.profile_img h1{
    color: #1A3A4C;
    padding-left: 18px;
}

.network_dots{
    cursor: pointer;
    margin-left: 75%;
}
.profile_dots{
    cursor: pointer;
}

.details-car h5{
    color: #1a3a4c;
    padding-top: 10px;
}


/* .nav-link.shown {
    background: #fff;
    color: #1a3a4c;
    width: 50px;
}

.three_dots{
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.three_dots li{
 display:inline-block;
} */

/* .three_dots{
    float: right;
    position: relative;
    top: 40px;
    left: 111px;
} */

.dropdown .show li a::after {
    content: "\f105";
    font-family: 'fontawesome';
    position: absolute;
    right: 9px;
    background: #fff;
    color: #1a3a4c;
    border-radius: 50%;
    padding: 0px 5px;
    height: 15px;
    width: 15px;
    line-height: .9;
    display: none;
    /* top: 3px; */
    /* bottom: -29px; */
}

.three_dots_colr{
    background-color:#1a3a4c !important; 
    color: #fff !important;
}

.threeDots{
    padding-top: 0;
    padding-bottom: 0;
    min-width: 80px;
    
}

.dropdown-menu .threeDots li a{
    min-width: 20px !important;
}


.three_dots {
    /* float: right; */
    /* position: relative; */
    /* top: 40px; */
    /* left: 111px; */
    display: block;
    text-align: right;
}

/* Afreen-setup your password */

.setup-password .setup-button {
    color: #1A3A4C;
    border-color: #1A3A4C!important;
    border: 2px solid;
    padding: 0px 30px 0px 30px;
    background-color: #1a3a4c00;
    font-weight: 800;
    height: 35px;
    font-size: 14px;
    border-radius: 6px;
}

.setup-password input {
    background-color: #EFEFEF;
    height: 30px;
}

.background {
    background-image: url(/public/images/act_img.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 352px;
    height: 360px;
    margin-bottom: 180px;
    padding: 46px 20px;
}

.setup-password h3 {
    font-weight: 900;
    font-size: 30px;
}

.setup-password .subscribe-button {
    color: #fff;
    font-weight: 500;
    background-color: #1A3A4C;
    margin-left: 10px;
    font-size: 12px;
    height: 32px;
}

.setup-password .setup-button {
    color: #1A3A4C;
    border-color: #1A3A4C!important;
    border: 2px solid;
    padding: 0px 30px 0px 30px;
    background-color: #1a3a4c00;
    font-weight: 800;
    height: 32px;
    font-size: 16px;
    border-radius: 6px;
    width: 168px;
}


.setup-password .forgot-pass {
    color: #FF6464;
    font-size: 14px;
    padding-left: 25px;
    font-family: 'museo500';
   
}


.setup-password .subscribe-button {
    color: #fff;
    font-weight: 500;
    background-color: #1A3A4C;
    font-size: 16px;
    width: 182px;
    height: 32px;
    margin: 37px 0 0 10px;
}

.setup-password .background p, .background h3 {
    font-size: 16px;
    width: 70%;
    padding-left: 10px;
    font-weight: 500;
    width: 203px;
height: 57px;
}

.setup-password h3, .setup-password p, .setup-password span, .setup-password label {
    color: #1A3A4C;
}

.setup-password .small-logo {
    width: 50px;
    padding-left: 10px;
}

.setup-password .John-id {
    padding-left: 10px;
    font-size: 20px;
}

.user {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 5px;
}

.setup-password label {
    font-size: 14px;
    font-weight: 700;
}

.setup-password .right-space {
    margin-right: 50px;
}
/* Afreen setup your password end */

/* Afreen  Dashbord_new css*/
.larng_point {
    background-color: #fff;
    width: 48%;
    margin-left: 16px;
    margin-bottom: 23px;
}


/* new-network */

/* .containsearchbox button i{
    color: red;

} */
/* end new-network */


.profile_user-list{
    position: relative;

}

.profileuserimg span {
    position: absolute;
    left: 84px;
    bottom: 33px;
}
/* .profileuserimg {
    padding-left: 8px;
    padding-top: 8px;
} */
/* .profileuserimg_bg  {
   background-color: #000;
   width: 100%;
} */

.userDetail h4{
    font-size: 20px;
    font-weight: 600;
    color: #1A3A4C;
    margin-bottom: 0;

}
.userDetail p{
    font-size: 14px;
    font-weight: 500;
    color: #000;

}
.userDetail {
    position: absolute;
    left: 122px;
    top: 20px;
    width: 50%;
}

.points-icon{
    position: absolute;
    top: 87px;
    right: 17%;
}
.points-icons{
    position: absolute;
    top: 87px;
    right: 4%;
}

.profile_projectlogo {
    color: #1A3A4C;
    font-family: 'museo700';
    line-height: 0;
    padding-bottom: 10px;
}

.subSuccess{
    font-family: 'museo100';
    font-size: 2rem;
    color: #1A3A4C;
}


.profile_subscribe_content {
    padding: 83px 0 0 30px;
    margin-bottom: 200px;
}
.profile_subscribe_content p{
   font-size: 16px;
   color: #000;
}


.profile_subscribe_content button {
    padding-top: 5px;
    text-align: center;
    width: 66%;
    border-radius: 5px;
    margin-top: 20px;
    color: #fff;
    background-color: #1a3a4c;
    font-size: 15px;
    height: 33px;
    margin-top: 0;
    border: none;
}


.profile_subscribe_img {
    background-image: url(/public/images/subscribe_imgg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 595px;
    /* margin-bottom: 200px; */
}


.profile_heading h1{
    font-size: 30px;
    color:#1a3a4c;
    margin-bottom: 18px;

}


.profileUserList textarea{
    height: 70px;
    width: 90%;
    background-color: #EFEFEF;
    font-size: 14px;
    font-style: italic;
}


.mgb .profile_user-list {
    background-color: #E3E3E3;
    height: 70px;
}

.userimgg{
    border-radius: 50%;
    border: 2px solid #fff;
}

.profileText p{
    padding-top: 30px;
}

.mywalluserDetail{
  
    padding: 20px 0 0 20px;
}

.points-icons img{
    border-radius: 50%;
}

.userimg{
    border-radius: 50%;
    border: 2px solid #fff;
}

.mywalluserDetail h4 {
    font-size: 20px;
    font-weight: 600;
    color: #1A3A4C;
    margin-bottom: 0;
}

.heart_images{
    margin-right: 10px;
}

.cust_input{
    width: 85% !important;
    background-color: #fff;
    height: 30px;
}

.realtorSucess{
    width: 86%;
}


.profileext p{
    margin-bottom: 0;
}

.profilemg{
    width: 86%;
    background-color: #F4F4F4;
    height: 130px;
}

/* course-new */
.coursepage_nw {
    background-image: url(/public/images/course-banner.png);
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    height: 199px;
    background-color: #131111;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 2.3rem;
}



.coursepage_nw h1 {
    font-size: 60px;
    font-family: 'museo100';
    padding-left: 25px;
}

.lable-heading_nw {
    font-family: 'museo700';
    color: #1a3a4c;
    font-size: 30px;
}

.img-box_nw {
    box-shadow: 0px 0px 5px 5px #e2e1e1;
    border-radius: 10px;
    width: 260px;
    width: 100%;
}

.course-cards_nw .img-fluid {
    border-radius: 4px 4px 0px 0px;
}


.details-card_nw {
    padding: 5px 15px 20px;
    background: #fff;
    border-radius: 0px 0px 4px 4px;
    box-shadow: 2px 2px 5px grey;
    margin-bottom: 5px;
    height: 153px;
}
.details-card_nw h5 {
    padding-top: 10px;
   
}

.course-head-al_nw {
    color: #1A3A4C;
    text-decoration: none;
    /* margin-right: 6px; */
    font-size: 20px;
    font-weight: 600;
    /* font-family: 'museo600'; */
}

.info-bottm-card_nw span {
   
    color: #1A3A4C;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 600;
}
.info-bottm-card_nw p {

    color: #1A3A4C;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 600;
}

.actionicons_nw {
    display: flex;
    padding-left: 0;
    justify-content: end;
    padding-right: 0;
}

.actionicons_nw i {
    width: 30px;
    height: 30px;
    background: #1a3a4c;
    color: #FF6C02;
    border-radius: 50%;
    padding: 8px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    margin-right: 5px;
}

.premium-tag_nw img {
    height: 14px;
    /* margin-right: 6px; */
    /* padding-left: 8px; */
}

.premium-tag_nw {
    position: absolute;
    left: 5px;
    top: 5px;
    background: #1a3a4c;
    border-radius: 5px;
    padding: 2px 10px;
    color: #fff;
    font-size: 12px;
}

.course-right-sec_nw {
    background-color: #fff;
    padding: 3rem 4rem;
}


/* course-new */

.dropselectinp_cn {padding: 0; padding-right: 10px;}

.info-bottm-card_cn p {
    color: #1A3A4C;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 300;
}

.action_cn p {
    display: flex;
    padding-left: 5px;
    justify-content: end;
    font-size: 16px;
    font-weight: 600;
}
.action_cn{
padding-top: 26px;
}
.action_cn img{
margin-top: 6px;
}


.coursepage_cn {
    background-image: url(/public/images/course_cn-img.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 249px;
    background-color: #131111;
    color: #fff;
    display: flex;
    /* align-items: center; */
    /* padding: 2.3rem; */
    padding: 31px 20px 20px 55px;
    flex-direction: column;
}

.coursepage_cn h1 {
    font-size: 50px;
    font-family: 'museo100';
    margin-top: 29px;
}

.coursepage_cn p{
    font-size: 18px;
    font-family: 'Roboto';
    color: #fff;
    padding-top: 4px;

}

 .custom_progress{
    margin-top: 9px;
}

/* progress.inprogress-bar-color .progress-bar {
    background-color: #FF6C00;
} */
    


.login_box {
    width: 40%;
    margin: auto;
}


#submit {
    background-color: limegreen;
  }
  
  .jumbotron {
    margin-top: 50px;
    background-color: snow;
  }
  .card {
    margin-left: 25px;
    margin-right: 25px;
    background-color: rgb(196, 192, 195);
  }
  
  hr {
    width: 70%;
  }

  .add_form {
    float: right;
    margin-bottom: 10px;
    padding: 5px !important;
  }

  .selected-menu{
    background: beige;
  }
  